var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"doc-right"},[_c('div',{staticClass:"doc-article"},[_c('h1',{staticClass:"tit"},[_vm._v(_vm._s(_vm.$t('msg.multiple_title')))]),_c('h4',[_vm._v("接口描述")]),_c('p',{staticClass:"text"},[_vm._v(" 翻译接口，为了翻译接口更加安全保密，同时v1老接口需要继续使用。 所以开展v2翻译接口，有保密和防破解功能，同时对接口做时间ip限制访问量。v1接口后续会在迭代中删除掉 ")]),_c('h4',[_vm._v("接口地址")]),_vm._m(0),_c('h4',[_vm._v("请求头")]),_vm._m(1),_c('h4',[_vm._v("接口加密操作方式")]),_c('p',{staticClass:"text mb-15"},[_vm._v("使用者被授权后，工作人员提供名字、包名等信息向后台申请，获取app_key和secret。app_key用于区分是用户，secret用于加密和进行验证身份。 客户端加密")]),_vm._m(2),_vm._m(3),_c('h4',[_vm._v("请求参数")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.apiData,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"name","label":"参数名称"}}),_c('el-table-column',{attrs:{"prop":"type","label":"类型"}}),_c('el-table-column',{attrs:{"prop":"isMust","label":"是否必要"}}),_c('el-table-column',{attrs:{"prop":"explain","label":"参数说明"}})],1),_c('h4',[_vm._v("请求实例")]),_vm._m(4),_c('h4',[_vm._v("响应参数")]),_c('h5',{staticClass:"mb-15"},[_vm._v("示例：")]),_c('p',{staticClass:"text mb-15"},[_vm._v("1000表示成功 其他表示失败 错误信息提示")]),_c('el-table',{staticClass:"mb-15",staticStyle:{"width":"100%"},attrs:{"data":_vm.errData,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"info","label":"错误信息"}}),_c('el-table-column',{attrs:{"prop":"explain","label":"说明"}})],1),_vm._m(5)],1),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',[_vm._v("POST https://api.translasion.com/v2/translate")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',[_vm._v("Content-Type:  application/json")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',{staticClass:"mb-15"},[_c('code',[_vm._v("nonce = 四位随机字符串\nstr = sprintf(\"%s&%s&%s&%s&%s\", app_key, to, timestamp, secret, nonce)\nsig = md5(str)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text"},[_c('span',{staticClass:"red"},[_vm._v("app_key, to, timestamp, secret, nonce")]),_vm._v("顺序要一致")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',[_vm._v("{\n    \"app_key\": \"10025\",\n    \"to\":\"en\",\n    \"texts\":[\n        \"fasdfa\",\n        \"fasdff\",\n        \"faweff\"\n    ]\n    \"from\":\"\",\n    \"timestamp\": 1231234213,\n    \"nonce\": \"acbf\",\n    \"sig\":\"feafefaefaefef\"\n}")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',[_vm._v("{\n    \"code\": \"1000\",\n    \"message\":\"success\",\n    \"result\": {\n        \"texts\":[\n            \"fawef\",\n            \"fawef\",\n            \"afewe\"\n        ]\n    }\n}")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"docs-subnav"},[_c('a',{attrs:{"href":"#"}}),_c('a',{attrs:{"href":"/docs/api/text/single"}},[_vm._v(" 单文本翻译 "),_c('i',{staticClass:"el-icon-arrow-right"})])])
}]

export { render, staticRenderFns }